<template>
  <div>
    <v-container
      grid-list-xl>
      <v-layout
        fluid
        pt-0
        wrap>
        <v-flex xs12>
          <app-card
            heading="Email Sending Messages"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <v-layout
                row
                wrap>
              <v-flex
                    xs12
                    sm6
                    md4>
                  <v-select
                    :items="statusesList"
                    v-model="filters.statusesFilter"
                    :menu-props="{ closeOnContentClick: true }"
                    :disabled="loading"
                    clearable
                    label="Status"
                    multiple
                  />
              </v-flex>
              <v-flex
                  xs12
                  sm6
                  md4>
                  <v-menu
                  ref="createdAtFrom"
                  v-model="createdAtFromFilterMenu"
                  :close-on-content-click="false"
                  :return-value.sync="filters.createdAtFromFilter"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  >
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      v-model="filters.createdAtFromFilter"
                      v-bind="attrs"
                      label="Created at from"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-on="on"
                      />
                  </template>
                  <v-date-picker
                      v-model="filters.createdAtFromFilter"
                      :first-day-of-week="1"
                      no-title
                      scrollable
                      range
                      @change="() => { $refs.createdAtFrom.save(filters.createdAtFromFilter); createdAtFromFilterMenu = false }"
                  />
                  </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm6
                md4>
                <v-menu
                  ref="createdAtTo"
                  v-model="createdAtToFilterMenu"
                  :close-on-content-click="false"
                  :return-value.sync="filters.createdAtToFilter"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  >
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      v-model="filters.createdAtToFilter"
                      v-bind="attrs"
                      label="Created at to"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-on="on"
                      />
                  </template>
                  <v-date-picker
                      v-model="filters.createdAtToFilter"
                      :first-day-of-week="1"
                      no-title
                      scrollable
                      range
                      @change="() => { $refs.createdAtTo.save(filters.createdAtToFilter); createdAtToFilterMenu = false }"
                  />
                </v-menu>
              </v-flex>
              <v-flex
                xs12
                sm6
                md4>
                <users-input
                  :disabled="loading"
                  v-model="filters.userFilter"
                  clearable
                  hide-details
                  @changeDriver="changeUserFilter"/>
              </v-flex>
              <v-flex
                xs12
                sm6
                md4>
                <v-select
                  :items="sorting"
                  v-model="filters.sortFilter"
                  :disabled="loading"
                  item-value="name"
                  item-text="title"
                  clearable
                  label="Sort"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md4>
                <v-text-field
                  v-model="filters.search"
                  class="mr-3"
                  label="Search"
                  outline
                  @input="searchHandler"/>
              </v-flex>
            </v-layout>
            <div>
              <v-data-table
                :headers="headers"
                :items="messagesList"
                class="elevation-1"
                :loading="loading"
                hide-actions
              >
                <template v-slot:headers="props">
                  <tr>
                    <th
                      v-for="header in props.headers"
                      :key="header.text"
                      :class="['column sortable', filters.sortDirFilter === 'desc' ? 'desc' : 'asc', header.value === filters.sortFilter ? 'active' : '']"
                      @click="changeTableSort(header)"
                    >
                      <v-icon
                        v-if="header.customSortable"
                        small>arrow_upward</v-icon>
                      {{ header.text }}
                    </th>
                  </tr>
                </template>
                <template v-slot:items="props">
                  <td class="c-pointer" @click="() => { openDetailsMessage(props.item) }">
                    {{ props.item.id }}
                  </td>
                  <td class="c-pointer" @click="() => { openDetailsMessage(props.item) }">
                    {{ props.item.transport }}
                  </td>
                  <td class="c-pointer" @click="() => { openDetailsMessage(props.item) }">
                    {{ props.item.status }}
                  </td>
                  <td class="c-pointer" @click="() => { openDetailsMessage(props.item) }">
                    {{ props.item.recipient }}
                  </td>
                  <td class="c-pointer" @click="() => { openDetailsMessage(props.item) }">
                    {{ props.item.template }}
                  </td>
                  <td style="white-space: nowrap">
                    <div v-if="props.item.user">
                      {{ props.item.user.name }} <br/> 
                      <router-link
                        v-if="props.item.user"
                        target="_blank"
                        :to="`/tables/users/${props.item.user.id}`"
                      >
                        id: {{ props.item.user.id }}
                      </router-link>
                    </div>
                  </td>
                  <td>
                    <router-link 
                      v-if="props.item.ride"
                      target="_blank"
                      :to="`/dashboard/ride-overview?rideId=${props.item.ride.id}`"
                    >
                      {{ props.item.ride.id }}
                    </router-link>
                  </td>
                  <td class="c-pointer" @click="() => { openDetailsMessage(props.item) }">
                    <div
                      v-for="(item, index) in props.item.history"
                      :key="index">
                      {{ gFunc.momentDateTime(item.occurredAt) }} &nbsp;<b> : </b>&nbsp;{{ item.description }}
                    </div>
                  </td>
                  <td class="c-pointer" @click="() => { openDetailsMessage(props.item) }">
                    {{ gFunc.momentDateTime(props.item.createdAt) }}
                  </td>
                </template>
              </v-data-table>
            </div>
          </app-card>
        </v-flex>
      </v-layout>
    </v-container>
    <edit-dialog
      :show.sync="editDialog.display"
      :max-width="'1200'"
      readonly
      @dialogClose="editDialog.closeFunction">
      <template>
        <v-container grid-list-xl>
          <v-layout
            row
            wrap>
            <v-flex
              xs12
              sm6
              md4>
              <v-text-field
                v-model="editDialog.data.id"
                readonly
                type="text"
                label="ID"
              />
            </v-flex>
            <v-flex
              xs12
              sm6
              md4>
              <v-text-field
                v-model="editDialog.data.transport"
                readonly
                type="text"
                label="Transport"
              />
            </v-flex>
            <v-flex
              xs12
              sm6
              md4>
              <v-text-field
                v-model="editDialog.data.status"
                readonly
                type="text"
                label="Status"
              />
            </v-flex>
            <v-flex
              xs12
              sm6
              md4>
              <v-text-field
                v-model="editDialog.data.recipient"
                readonly
                type="text"
                label="Recipient"
              />
            </v-flex>
            <v-flex
              xs12
              sm6
              md4>
              <v-text-field
                v-model="editDialog.data.template"
                readonly
                type="text"
                label="Template"
              />
            </v-flex>
            <v-flex
              xs12
              sm6
              md4>
              <b>User</b>
              <div v-if="editDialog.data.user">
                {{ editDialog.data.user.name }} <br/>
                <router-link
                  v-if="editDialog.data.user"
                  target="_blank"
                  :to="`/tables/users/${editDialog.data.user.id}`"
                >
                  id: {{ editDialog.data.user.id }}
                </router-link>
              </div>
            </v-flex>
            <v-flex
              xs12
              sm6
              md4>
              <b>Ride</b>
              <router-link 
                v-if="editDialog.data.ride"
                target="_blank"
                :to="`/dashboard/ride-overview?rideId=${editDialog.data.ride.id}`"
              >
                {{ editDialog.data.ride.id }}
              </router-link>
            </v-flex>
            <v-flex
              xs12
              sm6
              md4>
              <div>
                <b>History</b>
                <div
                  v-for="(item, index) in editDialog.data.history"
                  :key="index">
                  {{ gFunc.momentDateTime(item.occurredAt) }} &nbsp;<b> : </b>&nbsp;{{ item.description }}
                </div>
              </div>
            </v-flex>
            <v-flex
              xs12
              sm6
              md4>
              <v-text-field
                v-model="editDialog.data.createdAt"
                readonly
                type="text"
                label="Created At"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </edit-dialog>
  </div>
</template>
  
<script>
import Request from '../../../helpers/request'
import moment from 'moment-timezone'
import UsersInput from '../../../modules/components/UsersInput.vue'


export default {
  components: { UsersInput },
  mixins: [Request],
  data () {
    return {
      moment,
      messagesList: [],
      loading: false,
      filters: {
        sortFilter: null,
        sortDirFilter: null,
        createdAtFromFilter: null,
        createdAtToFilter: null,
        statusesFilter: null,
        userFilter: null,
        search: ''
      },
      createdAtFromFilterMenu: false,
      createdAtToFilterMenu: false,
      editDialog: {
        display: false,
        saveFunction: () => {},
        closeFunction: () => {},
        data: {}
      },
      sorting: [
        {
          title: 'Created At',
          name: 'createdAt',
          dir: 'desc'
        },
        {
          title: 'Id',
          name: 'id',
          dir: 'desc'
        }
      ],
      headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id',
          sortable: false,
          customSortable: true
        },
        {
          text: 'Transport',
          value: 'transport',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false
        },
        {
          text: 'Recipient',
          value: 'recipient',
          sortable: false,
        },
        {
          text: 'Template',
          value: 'template',
          sortable: false,
        },
        {
          text: 'User',
          value: 'user',
          sortable: false
        },
        {
          text: 'Ride',
          value: 'ride',
          sortable: false
        },
        {
          text: 'History',
          value: 'history',
          sortable: false
        },
        {
          text: 'Created At',
          value: 'createdAt',
          sortable: false,
          customSortable: true
        }
      ],
      statusesList: ['sent', 'pending', 'new', 'fail', 'bounced', 'soft-bounced', 'rejected', 'spam']
    }
  },
  async beforeMount () {
    const updatedProps = {}
    const _filters = { ...this.filters }

    if (this.$route.query.messageId) {
      await this.getMessageById(this.$route.query.messageId)
    }

    if (this.$route.query.search) {
      _filters.search = this.$route.query.search
      updatedProps.search = this.$route.query.search
    }

    if (this.$route.query.sort) {
      _filters.sortFilter = this.$route.query.sort
      updatedProps.sort = this.$route.query.sort
    } else {
      _filters.sortFilter = this.sorting[0].name
      updatedProps.sort = this.sorting[0].name
    }

    if (this.$route.query.sortDir) {
      _filters.sortDirFilter = this.$route.query.sortDir
      updatedProps.sortDir = this.$route.query.sortDir
    } else {
      _filters.sortDirFilter = this.sorting[0].dir
      updatedProps.sortDir = this.sorting[0].dir
    }

    if (this.$route.query.user) {
      _filters.userFilter = this.$route.query.user
      updatedProps.user = this.$route.query.user
    }

    if (this.$route.query.status) {
      _filters.statusesFilter = this.$route.query.status.split(',')
      updatedProps.status = this.$route.query.status
    }

    if (this.$route.query.createdAtFrom) {
      _filters.createdAtFromFilter = this.$route.query.createdAtFrom
      updatedProps.createdAtFrom = this.timeStamp(this.$route.query.createdAtFrom)
    }

    if (this.$route.query.createdAtTo) {
      _filters.createdAtToFilter = this.$route.query.createdAtTo
      updatedProps.createdAtTo = this.timeStamp(this.$route.query.createdAtTo)
    }

    this.filters = _filters

    this.getMessages(updatedProps)
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        this.updateParams()
      }
    },
  },
  methods: {
    async getMessageById (id) {
      this.loading = true
      const body = {
        params: {
          search: id,
          pageSize: 200
        }
      }

      await this.request('GET', `/messages`, body, ({ data }) => {
        this.loading = false
        const message = data.find(item => item.id === parseInt(id))

        if (message) {
          this.openDetailsMessage(message)
        }
      })
    },
    openDetailsMessage (message) {
      if (message) {
        this.editDialog.display = true
        this.editDialog.data = message
        this.$router.replace({ query: { ...this.$route.query, messageId: message.id } }).catch(err => err)

        this.editDialog.closeFunction = () => {
          this.$router.replace({ query: { ...this.$route.query, messageId: undefined } }).catch(err => err)
        }
      }
    },
    async getMessages (params = {}) {
      this.loading = true
      let body = { params: { ...params, pageSize: 200 } }

      await this.request('GET', `/messages`, body, ({ data }) => {
        this.loading = false
        this.messagesList = data
      })
    },
    changeUserFilter (inputUser) {
      this.filters.userFilter = inputUser.id
    },
    changeTableSort (headerColumn) {
      if (!headerColumn.customSortable) {
        return null
      }

      this.filters = {
        ...this.filters,
        sortFilter: headerColumn.value,
        sortDirFilter: this.filters.sortDirFilter === 'desc' ? 'asc' : 'desc'
      }
    },
    searchHandler (value) {
      this.filters.search = value
      this.getMessages({ ...this.filters, search: value })
    },
    updateParams () {
      let params = {}

      let updatedRouteQuery = { ...this.$route.query }

      if (this.filters.search) {
        params.search = this.filters.search
        updatedRouteQuery = { ...updatedRouteQuery, search: this.filters.search }
      } else {
        delete updatedRouteQuery.search
        delete params.search
      }

      if (this.filters.sortFilter) {
        params.sort = this.filters.sortFilter
        updatedRouteQuery = { ...updatedRouteQuery, sort: this.filters.sortFilter }
      }

      if (this.filters.sortDirFilter) {
        params.sortDir = this.filters.sortDirFilter
        updatedRouteQuery = { ...updatedRouteQuery, sortDir: this.filters.sortDirFilter }
      }

      if (this.filters.statusesFilter && this.filters.statusesFilter.length) {
        updatedRouteQuery = { ...updatedRouteQuery, status: this.filters.statusesFilter.join(',') }
        params.status = this.filters.statusesFilter.join(',')
      } else {
        delete updatedRouteQuery.status
        delete params.status
      }

      if (this.filters.createdAtFromFilter) {
        updatedRouteQuery = { ...updatedRouteQuery, createdAtFrom: this.filters.createdAtFromFilter }
        params.createdAtFrom = this.timeStamp(this.filters.createdAtFromFilter)
      } else {
        delete updatedRouteQuery.createdAtFrom
        delete params.createdAtFrom
      }

      if (this.filters.createdAtToFilter) {
        updatedRouteQuery = { ...updatedRouteQuery, createdAtTo: this.filters.createdAtToFilter }
        params.createdAtTo = this.timeStamp(this.moment(this.filters.createdAtToFilter).set('hours', 24))
      } else {
        delete updatedRouteQuery.createdAtTo
        delete params.createdAtTo
      }

      if (this.filters.userFilter) {
        const userId = this.filters.userFilter.id || this.filters.userFilter
        params.user = userId
        updatedRouteQuery = { ...updatedRouteQuery, user: userId }
      } else {
        delete updatedRouteQuery.user
        delete params.user
      }

      this.$router.replace({ query: updatedRouteQuery }).catch(err => err)

      this.getMessages(params)
    }
  }
}
</script>